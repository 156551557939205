import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import HTMLReactParser from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap"
import queryString from "query-string"

export default function ThankYou({ data: { nodeThankYou }, pageContext }) {
  const isSSR = typeof window === "undefined"

  const {
    title,
    field_seo,
    thank_you_body,
    relationships: {
      thank_you_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
    }
  } = nodeThankYou

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  let parsed, personName
  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    personName = parsed?.name ? parsed.name : ""
  }

  let thankYouBody;

  if (personName) {
    //Replace "&lt;prenom&gt; &lt;surname&gt;" in thank_you_body.processed by the user name
    thankYouBody = thank_you_body.processed.replace(
      /&lt;prenom&gt; &lt;surname&gt;/g,
      personName
    )
  }

  return (
    <Layout pageContext={pageContext} pageTitle={title}>
       <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} isHeightSmall={1} />
      )}
      <section className="blk-wysiwig pt-50 pb-50">
        <Container>
          <Row>
            <Col>
              {thankYouBody && HTMLReactParser(thankYouBody)}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeThankYou(id: { eq: $id }) {
        title
        field_seo {
          description
          title
          og_description
          og_image
          og_locale
          og_url
          og_title
          og_type
        }
        thank_you_body {
          processed
        }
        relationships {
          thank_you_carousel {
            relationships {
              carousel_highlight_item {
                title
                caption
                subtitle
                cta {
                  title
                  uri
                  url
                }
                relationships {
                  full_screen_video {
                    field_media_oembed_video
                  }
                  media {
                    ... on media__image {
                      field_image {
                        alt
                      }
                      internal {
                        type
                      }
                      relationships {
                        ...getHeroCms
                      }
                    }
                    ... on media__wistia {
                      internal {
                        type
                      }
                      field_media_oembed_video
                      relationships {
                        thumbnail {
                          gatsbyImage(width: 1920, formats: WEBP)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
  }
`
